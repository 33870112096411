<template>
  <div class="headNav">
    <div class="head">
      <div class="mainContent">
        <img @click="getActive('Inicio')" class="logo" src="@/assets/imgs/kary_logo.png" alt="">
        <ul class="nav">
          <li :class="activeNav== 'Inicio'? 'active' : ''">
            <a @click="getActive('Inicio')">Inicio</a>
            <div class="kary_navsolid">
              <img src="@/assets/imgs/kary_navsolid.png" alt="">
            </div>
          </li>
          <li :class="activeNav== 'Ventajas'? 'active' : ''">
            <a @click="getActive('Ventajas')">Ventajas</a>
            <div class="kary_navsolid">
              <img src="@/assets/imgs/kary_navsolid.png" alt="">
            </div>
          </li>
          <li :class="activeNav== 'Sobre'? 'active' : ''">
            <a @click="getActive('Sobre')">Sobre nosotros</a>
            <div class="kary_navsolid">
              <img src="@/assets/imgs/kary_navsolid.png" alt="">
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import "./scss/nav.scss";

export default {
  name: "headNav",
  //props: [ 'activeNav' ],
  data() {
    return {
      activeNav: "Inicio",
    }
  },
  created() {

  },
  methods: {

    getActive(val) {
      this.activeNav = val;

      this.$emit('handeleNavchange', val)
    },
  }
}
</script>

<style lang="scss">

  .p3.item {
    cursor: pointer;
  }
  .playBtn img {
    height: auto !important;
  }
</style>
