<template>
  <div>
    <div class="headNav" style="width: 100%;position: fixed; left: 0; top:0;">
      <div class="head">
        <div class="mainContent">
          <img  style="width: 50px;height: 50px; top: 20px;" @click="getActive('Inicio')" class="logo" src="@/assets/imgs/kary_logo_new.png" alt="">
          <ul class="nav">
          </ul>
        </div>
      </div>
    </div>
    <div class="content" style="padding-top: 88px;">
      <p>
        En cumplimiento a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en adelante la “Ley”), su Reglamento y los Lineamientos del Aviso de Privacidad, estos últimos publicados en el Diario Oficial de la Federación de fecha 17 de enero de 2013, la aplicación KARYWORLD pone a su disposición el presente Aviso de Privacidad y hace de su conocimiento los términos y condiciones siguientes:
      </p>
      <h5>I. Del Tratamiento de los Datos Personales.</h5>
      <p>
        La aplicación KARYWORLD, con domicilio en Ciudad de México, es responsable de la obtención, uso, divulgación, o almacenamiento, acceso, manejo, aprovechamiento, transferencia o disposición de datos personales (adelante “Tratamiento”).
      </p>
      <h5>II. Datos Personales sujetos a Tratamiento.</h5>
      <p>
        KARYWORLD recabará Datos Personales necesarios para cumplir con sus objeto social, políticas en materia de prevención y detección de operaciones con recursos de procedencia ilícita y financiamiento al terrorismo y demás políticas corporativas, ya sea personal, directamente a través de sus ejecutivos, funcionarios, empleados, promotores, o indirectamente por medio de sus asesores o comisionistas, pudiendo utilizar mecanismos en medios remotos o locales de comunicación electrónica, óptica u otra tecnología, como puede ser a través de nuestro portal web, correo electrónico o postal, servicios en línea, vía telefónica, que le permitan recabar datos personales de manera automática y simultánea, incluyendo las referencias personales y comerciales que llegue a proporcionar el titular de la información.
      </p>
      <p>
        Los Datos Personales podrán incluir todos o algunos de los siguientes datos de la persona física actuando de manera personal y como representante legal:
      <ul>
        <li>
          Datos de Identificación: nombre completo, estado civil, género, fecha de nacimiento, edad, país, identificación oficial vigente, registro federal de contribuyentes (RFC), clave única de registro de población (CURP), Firma electrónica avanzada (FIEL), correo electrónico, datos académicos y sobre características físicas como son fotografías del poseedor de los datos o su domicilio, entre otros.
        </li>
        <li>
          Datos Personales: informaciones laboral, salarios mensuales, día de cobro, nivel educativo, informaciones bancarias, tipo de cuenta, número de tarjeta.
        </li>
        <li>
          Datos Laborales: actividad principal, tipo de empleo, fuentes de ingresos, ingresos mensuales, dirección del centro de trabajo, teléfono del centro de trabajo, fecha de ingreso al servicio, cargo dentro de la empresa/institución, nombre del jefe directo o representante del centro de trabajo, y actividades productivas adicionales, entre otros.
        </li>
        <li>
          Datos Comerciales: fuente de ingresos, actividades empresariales, experiencia profesional o empresarial y otros relacionados.
        </li>
        <li>
          Datos de Cónyuge o Concubina: nombre completo, dirección, ocupación, teléfonos de contacto, estado civil, género, país, lugar y fecha de nacimiento, edad.
        </li>
        <li>
          Datos Bancarios o patrimoniales: nombre de la institución bancaria donde recibe su nómina o en la cual recibe sus ingresos, número de cuenta y/o tarjeta y/o contrato, Clave Bancaria Estandarizada (CLABE), tipo de cuenta, nombre y número de la sucursal y/o plaza, entre otros, como pueden ser la huella, foto y firma digital.
        </li>
        <li>
          Datos de SMS: incluye datos de contactos, números de teléfono, mensajes SMS. (Nuestra aplicación cargará y transferirá sus datos de SMS a nuestro servidor para realizar gestión de riesgos y controles antifraude. Nunca compartiremos datos con terceros sin su permiso, si desea eliminar sus datos puede enviar una solicitud al correo cumplimiento_pld@karyworld.com, y los eliminamos dentro de 3-5 días)
        </li>
        <li>
          Datos de Modelo y Estado del Dispositivo: información específica sobre su dispositivo, incluido su modelo de hardware, modelo de compilación, RAM, almacenamiento, identificadores únicos de dispositivo como IMEI, número de serie, SSAID,AAID, información SIM.
        </li>
        <li>
          Datos de Ubicación: recopilamos y detectamos la información de ubicación de su dispositivo para brindarle servicios para su solicitud de préstamo, reducir los riesgos asociados con su solicitud de préstamo.
        </li>
        <li>
          Cámara e información de imágenes: Solicitamos acceso de cámara e información de imágenes únicamente con el fin de tomar una foto de su rostro y otra foto de su documentos de identidad, para realizar verificación de la autenticidad de su identidad y evitar actividades fraudulentas. Durante este proceso, las fotos se guardarán temporalmente en su galería para luego cargar a nuestro base de datos. No compartiremos sus datos con terceros sin su permiso, y sus datos se conservarán en nuestra plataforma durante 1 año a partir de la fecha de carga. Si desea eliminar sus datos personales, puede enviar una solicitud a cumplimiento_pld@karyworld.com. Eliminaremos manualmente sus datos en un plazo de 3 a 5 días hábiles.
        </li>
        <li>
          Calendario: Para reducir riesgo de fraude y evaluar la aptitud de usuario como recipiente de un préstamo, necesitamos acceder el calendario y analizar los datos. También insertamos recordatorios en el calendario para recordarle la fecha de vencimiento de su pago. Los datos generados como título, descripción, ID del calendario y fecha de inicio/fin se cargarán en nuestra plataforma. No compartiremos sus datos con terceras sin su permiso, y sus datos se conservarán en nuestra plataforma durante 1 año a partir de la fecha de carga. Si desea eliminar sus datos puede enviar una solicitud a cumplimiento_pld@karyworld.com. Eliminaremos manualmente sus datos en un plazo de 3 a 5 días hábiles.
        </li>
        <li>
          Datos de Actividad en Apps: la actividad en app, incluido nombre del paquete, nombre de la aplicación, la hora de la primera instalación y la hora de actualización de la aplicación.
        </li>
        <li>
          Datos de Contactos: Necesitamos que complete la información de contacto manualmente u automáticamente, incluido nombre y número de teléfono, para que podamos comunicarnos con usted de manera efectiva después de que su préstamo esté vencido y haya perdido el contacto. Si desea eliminar sus datos privados, puede enviar un correo electrónico a cumplimiento_pld@karyworld.com Envíe una solicitud al servicio de atención al cliente y podemos eliminarla manualmente dentro de (3-5 días hábiles).
        </li>
        <li>
          Eliminación de Datos: Todas las informaciones cargadas a nuestro servidor se puede ser eliminadado en cualquier momento enla administración de la aplicación en el dispositivo móvil (el cierre puede afectar los servicios que le brindamos). Sus datos se mantendrán en la plataforma durante 1 año a partir de la fecha de carga. Si desea eliminar sus datos privados, puede enviar una solicitud al servicio de atención al cliente al correo cumplimiento_pld@karyworld.com, y podemos eliminarlos manualmente dentro de (3-5 días hábiles).
        </li>
        <li>
          Datos de Permisos dentro del Dispositivo: en especifico, las informaciones que emiten tras la activación de permiso de SMS, modelo y estado de dispositvo, ubicacion, informacion del usuario, camara y calendario.
        </li>
      </ul>
      <p>
        Todos los Datos Personales recabados serán tratados bajo estrictas medidas de seguridad, siempre garantizando su confidencialidad. KARYWORLD no recabará datos sensibles, es decir, aquellos que puedan revelar aspectos como origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas y preferencia sexual.
      </p>
      <h5>III. Finalidades del Tratamiento.</h5>
      <p>
        Los Datos Personales que KARYWORLD recabe serán utilizados para realizar la verificación y confirmación de su identidad, análisis de riesgos, y que pudieran dar origen a una relación comercial entre KARYWORLD y usted como titular de los Datos Personales, siendo las finalidades:
      </p>
      <ul>
        <li>Dar cumplimiento a obligaciones contraídas con el Titular; ;</li>
        <li>
          Dar cumplimiento a obligaciones regulatorias emitidas por parte de las autoridades competentes, así como atender sus requerimientos;
        </li>
        <li>Mantener su información actualizada..</li>
        <li>Identificar al Titular. .</li>
        <li>
          Evaluar riesgos y, en su caso, formalizar el contrato que derive de la relación comercial que se establezca entre las partes.
        </li>
        <li>
          Realizar validaciones de identidad a través de medios biométricos y biográficos de sus clientes.
        </li>
      </ul>
      <h5>
        IV. Mecanismos para manifestación de negativa para tratamiento de sus
        Datos Personales.
      </h5>
      <p>
        En caso de que no se encuentre de conformidad con el presente Aviso de Privacidad y del tratamiento de sus Datos Personales, contará con un plazo de 5 (cinco) días hábiles contados a partir de la entrega del presente Aviso de Privacidad para que, de ser el caso, manifieste su negativa para el tratamiento de sus Datos Personales. Para llevar a cabo el ejercicio de sus derechos de conformidad con lo señalado en el párrafo anterior, se pone a su disposición el correo electrónico cumplimiento_pld@karyworld.com en el cual, dentro del asunto deberá señalar “Negativa para el Tratamiento de Datos Personales” manifestando en el cuerpo del correo sus datos de identificación a efecto de realizar lo conducente. En caso de manifestar su negativa para el tratamiento de sus Datos Personales en términos del presente Aviso de Privacidad, no se podrá concretar la relación jurídica, en el entendido que dicha información, es requerida para la prestación del servicio.
      </p>
      <h5>V. Transferencia de Datos.</h5>
      <p>
        KARYWORLD podrá transferir sus Datos Personales a terceros, comprometiéndose a informar a dichos terceros del contenido del presente Aviso de Privacidad a fin de que tomen las medidas necesarias para respetar en todo momento los compromisos aquí descritos y asumidos por nosotros, mismos que serán extensivos para dichos terceros. KARYWORLD se cerciorará de que los terceros hayan emitido igualmente su correspondiente Aviso de Privacidad, cumpliendo así con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares. Los Datos Personales podrán ser transferidos a:
      </p>
      <ul>
        <li>
          Sociedades prestadoras de servidos, tales como: Trans Unión de México, S.A. S.I.C. (Buro de crédito), Circulo de Crédito S.A. de C.V. S.I.C., Servicios Quien es Quien, S.A. de C.V., únicamente con el propósito de dar cumplimiento a las obligaciones legales y normativas de KARYWORLD derivadas de lo previsto en las leyes mexicanas que rigen a una Sociedad Financiera de Objeto Múltiple No Regulada.
        </li>
        <li>
          Autoridades mexicanas, cuando éstas lo requieran, para validar la autenticidad de los documentos presentados como identificación oficial por la contraparte.
        </li>
        <li>
          Autoridades financieras mexicanas y extranjeras, cuando éstas lo requieran con la finalidad de dar cumplimiento con las obligaciones derivadas de leyes o tratados internacionales como entidad financiera, obligaciones tributarias, así como para el cumplimiento de notificaciones o requerimientos oficiales.
        </li>
        <li>
          Autoridades judiciales mexicanas y extranjeras, con la finalidad de cumplir con notificaciones, requerimientos u oficios de carácter judicial.
        </li>
      </ul>
      <p>
        Todos los terceros mencionados pueden participar dentro de los procesos y cadena de valor ofrecida por KARYWORLD. En caso de ser necesaria la transferencia adicional de información que requiera su consentimiento expreso, éste se recabará con anticipación. KARYWORLD, podrá proporcionar información recabada previamente y durante la vigencia de la relación jurídica entre las partes para el cumplimiento de las obligaciones derivadas de lo anterior, a las sociedades controladoras, subsidiarias o afiliadas, o a una sociedad matriz o a cualquier sociedad del mismo grupo de KARYWORLD, así como a las autoridades competentes en México conforme a la legislación aplicable y para el cumplimiento de obligaciones legales ante autoridades administrativas y judiciales garantizando previamente que dicha transferencia otorgue la continuidad de la protección de la información y el cumplimiento a lo señalado en el presente Aviso de Privacidad. El titular expresamente acepta y conviene que KARYWORLD podrá grabar cualquier comunicación telefónica que se realice con KARYWORLD.
      </p>
      <h5>
        VI. Limitaciones en el uso y divulgación de sus Datos Personales y ejercicio de los derechos ARCO (Acceso, Rectificación, Cancelación y Oposición)
      </h5>
      <p>
        Usted tiene el derecho de acceder a sus Datos Personales en poder de KARYWORLD y a los detalles del tratamiento de estos, así como a rectificarlos en caso de ser inexactos o bien cancelar su uso a través de una solicitud por escrito dirigida a la siguiente dirección de correo electrónico cumplimiento_pld@karyworld.com o bien, mediante la presentación de un escrito libre dentro de las instalaciones de KARYWORLD, ubicadas en Ciudad de México; en un horario de 9:00 a 15:00 hrs. Dicho requerimiento será atendido por la UNE (Unidad Especializada de Atención a Usuarios) en un plazo máximo de 20 (veinte) días hábiles, informándole sobre la procedencia a través del correo electrónico o el número telefónico que haya indicado en su solicitud y haciéndose efectiva, 15 (quince) días hábiles después de que se hubiera comunicado su resolución. Los plazos antes referidos podrán ser ampliados una sola vez por un periodo igual, siempre y cuando así lo justifiquen las circunstancias del caso, razones que se manifestarán al Titular, por escrito. Para ejercer los Derechos ARCO, el Titular, deberá presentar ante KARYWORLD, una solicitud que deberá contener y acompañar, como mínimo, lo siguiente:
      </p>
      <ul>
        <li style="list-style: none">
          1. El nombre completo del Titular, domicilio y cuenta de correo electrónico o cualquier medio para comunicarle la respuesta a la solicitud.
        </li>
        <li style="list-style: none">
          2. Los documentos que acrediten la identidad o, en su caso, la representación legal del Titular.
        </li>
        <li style="list-style: none">
          3. La descripción clara y precisa de los Datos Personales sobre los cuales se desea ejercer algún Derecho ARCO.
        </li>
        <li style="list-style: none">
          4. Para el caso de solicitar una rectificación, se deberá acompañar la documentación que respalde la rectificación solicitada.
        </li>
      </ul>
      <p>
        KARYWORLD se reserva el derecho de solicitar información y/o documentación adicional a la mencionada para atender la solicitud. KARYWORLD no estará obligado a cancelar los datos personales cuando: (i) Se refiera a las partes de un contrato y sean necesarios para su desarrollo y cumplimiento, (ii) deban ser tratados por disposición legal y (iii) sean necesarios para cumplir con una obligación legalmente adquirida por el Titular. La obligación de acceso a la información se dará por cumplida por parte de KARYWORLD, cuando ésta ponga a disposición del Titular de los Datos Personales directamente en el domicilio señalado en este inciso o se envíe al correo electrónico señalado por el Titular y elimine de su base de datos, dichos datos. Usted manifiesta expresamente que es de su conocimiento que en cualquier momento podrá oponerse al tratamiento de los datos que no sean esenciales para la relación jurídica y/o comercial que entabla con KARYWORLD, lo cual podrá efectuar mediante el procedimiento aquí señalado. En el tratamiento de sus datos personales, se prevé primordialmente que su resguardo sea contra todo daño, pérdida, alteración, destrucción, y ante todo uso, acceso o tratamiento no autorizado.
      </p>
      <h5>VII. Modificaciones al Aviso de Privacidad.</h5>
      <p>
        Como Titular de los Datos Personales podrá revocar el consentimiento concedido a KARYWORLD a fin de que se deje de hacer uso de los mismos, para lo cual se deberá seguir el procedimiento señalado en el inciso VI del presente Aviso de Privacidad.
      </p>
      <h5>VIII. Revocación del consentimiento.</h5>
      <p>
        Como Titular de los Datos Personales podrá revocar el consentimiento concedido a KARYWORLD a fin de que se deje de hacer uso de los mismos, para lo cual se deberá seguir el procedimiento señalado en el inciso VI del presente Aviso de Privacidad.
      </p>
      <h5>IX. DECISIONES AUTOMATIZADAS</h5>
      <p>
        Tomamos algunas decisiones en relación con nuestros productos y servicios en forma automatizada sin intervención de una persona física en la valoración.
      </p>
      <p style="font-weight: bold;">Decisiones de otorgar préstamos</p>
      <p>
        Cuando solicitas un préstamo, usaremos un proceso automatizado para decidir si prestamos dinero y/o para toma de decisiones respecto a tu elegibilidad para nuestros productos o servicios.
      </p>
      <p>
        Nuestros procesos de revisión y otorgamiento de crédito utilizan inteligencia artificial para procesar tus datos personales y valorar tu capacidad crediticia. El tratamiento de tus datos personales es automatizado sin intervención o valoración humana. El uso de dichos procesos automatizados para verificar tu capacidad crediticia significa que podemos automáticamente decidir que no eres elegible para un préstamo o para un plazo o condiciones particulares. Nuestros modelos de suscripción y otorgamiento de crédito son probados regularmente para asegurarnos que son justos, exactos y sin prejuicios.
      </p>
      <p style="font-weight: bold;">Detección de fraudes</p>
      <p>
        KARYWORLD también utiliza procesos automatizados para detectar, combatir y prevenir fraudes. Nuestros modelos de detección de fraudes automáticamente deciden si una persona puede significar riesgo de fraude o lavado de dinero. Si nuestros modelos de detección de fraude al procesar una transacción o aprobar un crédito detectan riesgo, es posible que el acceso a la persona se rehuse.
      </p>
      <p style="font-weight: bold;">Tus derechos</p>
      <p>
        Si solicitas un crédito y eres rechazado basado en una decisión automatizada, tienes derecho a acceder a tu información y rectificarla contactándonos como se establece en la sección Derechos ARCO.
      </p>
      <h5>X. Medio de comunicación con nosotros</h5>
      <p>Si tiene cualquier duda sobre nuestro Aviso de Privacidad, por favor no dudes en ponerse en contacto con nuestro atención al cliente tras llamada, correo electrónico o whatsapp. </p>
      <p>Atención al cliente：5554629815</p>
      <p>Email：cumplimiento_pld@karyworld.com (respuesta en 2 a 3 días laborales) </p>
      <p>Whatsapp：5554629815</p>
      <p>Tiempo laboral：De lunes a viernes 9:00-18:40, de sábado a domingo 9:00-14:40 </p>

    </div>

    <div class="karyfooter_wrap">
      <img src="@/assets/imgs/karyfooter_bgimg.webp" alt="">
      <div class="karyfooter_view">
        <div class="karyfooter_vtext">
          El Buró de Entidades Financieras contiene información de KARYWORLD, S.A. DE C.V., SOFOM, E.N.R. sobre las características de nuestra aplicación y nuestro desempeño frente a los usuarios en la prestación de servicios.
          La información que de ellas conste en el Buró y la indicación de que la información corresponde únicamente a la Entidad Financiera de que se trate y que para conocer la información de todo el sector a la que corresponda. Te invitamos a consultarlo en la página https://www.buro.gob.mx/
          <div class="karyfooter_vprivace" @click.stop="onGotoPrivace">

        </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
  import "@/components/scss/nav.scss";

  export default {
    name: "headNav",
    //props: [ 'activeNav' ],
    data() {
      return {
        activeNav: "Inicio",
      }
    },
    created() {

    },
    methods: {
      getActive() {
        let url = "/home";
        this.$router.push(url);
      },
      onGotoPrivace() {
        this.$router.push('/privace');
      }
    }
  }

</script>

 <style scoped>
    .content {
      width: 1080px;
      margin: 20px auto;
      padding-bottom: 20px;
    }
    h5 {
      font-size: 23px;
      margin-bottom: 10px;
    }
    li {
      margin-bottom: 10px;
      font-size: 18px;
    }
    p {
      font-size: 18px;
      color: #999;
      margin-bottom: 10px;
    }
    .karyfooter_wrap{
      width: 100%;
      height: 560px;
      position: relative;
    }
    .karyfooter_wrap img{
      display: block;
      width: 100%;
      height: 560px;
    }
    .karyfooter_view{
      width: 100%;
      height: 560px;
      position: absolute;
      left: 0;
      top: 0;
    }
    .karyfooter_vtext{
      width: 1400px;
      height: auto;
      margin: 240px auto 0 auto;
      font-weight: 400;
      font-size: 28px;
      color: rgba(255,255,255,0.64);
      line-height: 38px;
      text-align: left;
      font-style: normal;
      position: relative;
    }
    .karyfooter_vprivace{
      width: 200px;
      height: 50px;
      position: absolute;
      left: 350px;
      top: -150px;
      cursor: pointer;
    }
</style>
