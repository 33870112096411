<template>
  <div class="Inicio">
    <div class="banner">

    </div>

    <div class="kary_topview">
      <div class="kary_topvtext">
        <span>KARYWORLD</span>es una empresa de préstamos en línea que opera exclusivamente en México. Dedicamos a brindar servicios de préstamos en línea convenientes y eficientes para satisfacer las necesidades de nuestros usuarios.
      </div>
    </div>

    <div id="karycard_view" class="p3">
      <div class="mainContent">
        <div class="karytitle_one">
          <img src="@/assets/imgs/kary_titleone.png" alt="" />
        </div>
        <ul class="karycard_list">
          <li style="list-style: none !important" class="karycard_itemone">
            <!--<img class="karycard_itemnormal" src="@/assets/imgs/karycard_itemone.png" alt="" />-->
            <!--<img class="karycard_itemhover" src="@/assets/imgs/karycard_itemone.png" alt="" />-->
          </li>
          <li style="list-style: none !important" class="karycard_itemtwo">
            <!--<img src="@/assets/imgs/karycard_itemtwo.png" alt="" />-->
          </li>
        </ul>
        <ul class="karycard_list">
          <li style="list-style: none !important" class="karycard_itemthr">
            <!--<img src="@/assets/imgs/karycard_itemthr.png" alt="" />-->
          </li>
          <li style="list-style: none !important" class="karycard_itemfur">
            <!--<img src="@/assets/imgs/karycard_itemfur.png" alt="" />-->
          </li>
        </ul>
      </div>
    </div>

    <div class="kary_sobre_wrap">
      <div class="kary_sobre_title">
        <img src="@/assets/imgs/kary_sobre_title.png" alt="">
      </div>
      <div class="kary_sobre_view">
        <div class="kary_sobre_vleft">
          <img src="@/assets/imgs/kary_sobre_limg.png" alt="">
        </div>
        <div class="kary_sobre_vcenter">
          <img src="@/assets/imgs/kary_sobre_cimg.png" alt="">
        </div>
        <div class="kary_sobre_vright">
            <div class="kary_sobre_vritems">
              <div class="kary_sobre_vriicon">
                <img src="@/assets/imgs/kary_sobre_rimgone.png" alt="">
              </div>
              <div class="kary_sobre_vritext">
                <div class="kary_sobre_vritname">Teléfono atención al cliente: </div>
                <div class="kary_sobre_vritnumber">+52 5554629815</div>
              </div>
            </div>
            <div class="kary_sobre_vritems">
              <div class="kary_sobre_vriicon">
                <img src="@/assets/imgs/kary_sobre_rimgtwo.png" alt="">
              </div>
              <div class="kary_sobre_vritext">
                <div class="kary_sobre_vritname">Correo electrónico: </div>
                <div class="kary_sobre_vritnumber">cumplimiento_pld@karyworld.com</div>
              </div>
            </div>
            <div class="kary_sobre_vritems">
              <div class="kary_sobre_vriicon">
                <img src="@/assets/imgs/kary_sobre_rimgthr.png" alt="">
              </div>
              <div class="kary_sobre_vritext">
                <div class="kary_sobre_vritname">WhatsAPP: </div>
                <div class="kary_sobre_vritnumber">+52 5554629815</div>
              </div>
            </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import "./scss/Inicio.scss";

export default {
  name: "Inicio",
  data() {
    return {

    };
  },
  created() {

  },
  methods: {
    go() {
      //this.$router.push("/notice");
    },
  },
};
</script>

<style lang="scss">
  .p3.item {
    cursor: pointer;
  }
  .playBtn img {
    height: auto !important;
  }
  .kary_topview{
    width: 100%;
    height: auto;
    background: #ffffff;
  }
  .kary_topvtext{
    width: 984px;
    height: auto;
    margin: 84px auto 104px auto;
    font-weight: bold;
    font-size: 38px;
    color: #373838;
    line-height: 46px;
    text-align: left;
    font-style: normal;
  }
  .kary_topvtext span{
    font-size: 42px;
    padding-right: 10px;
  }
  .karytitle_one{
    width: 778px;
    height: 64px;
    margin: 98px auto 100px auto;
  }
  #karycard_view .karytitle_one img{
    display: block;
    width: 778px;
    height: 64px;
  }
  #karycard_view .karycard_list{
    width: 1160px;
    height: 696px;
    display: flex;
    justify-content: space-between;
    background: #F7F9FB;
    margin-top: 0;
  }
  #karycard_view .karycard_list li{
    width: 540px;
    height: 620px;
    box-sizing: border-box;
    padding: 0;
  }
  #karycard_view .karycard_list li img{
    display: block;
    width: 540px;
    height: 620px;
  }
  .karycard_itemnormal{
    display: block;
  }
  .karycard_itemhover{
    display: none;
  }
  /*#karycard_view .karycard_list li:hover {*/
   /**/
    /*box-shadow: 0px 5px 5px #dddddd;*/
    /*position: relative;*/
    /*top: 10px;*/
  /*}*/
  /*#karycard_view .karycard_list li:hover > .karycard_itemhover{*/
    /*display: block;*/
  /*}*/
  .kary_sobre_wrap{
    width: 100%;
    height: 732px;
    background: #ffffff;
    box-sizing: border-box;
    padding: 98px 0 0 0;
  }
  .kary_sobre_title{
    width: 494px;
    height: 165px;
    margin: 0 auto;
  }
  .kary_sobre_title img{
    display: block;
    width: 494px;
    height: 64px;
  }
  .kary_sobre_view{
    width: 1106px;
    height: 299px;
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;
  }
  .kary_sobre_vleft{
    width: 552px;
    height: 299px;
    box-sizing: border-box;
    padding: 9px 0 0 0;
  }
  .kary_sobre_vleft img{
    display: block;
    width: 433px;
    height: 284px;
  }
  .kary_sobre_vcenter{
    width: 3px;
    height: 299px;
  }
  .kary_sobre_vcenter img{
    display: block;
    width: 3px;
    height: 299px;
  }
  .kary_sobre_vright{
    width: 520px;
    height: 299px;
    box-sizing: border-box;
    padding: 21px 0 0 121px;
  }
  .kary_sobre_vritems{
    width: 100%;
    height: 100px;
    display: flex;
    justify-items: flex-start;
  }
  .kary_sobre_vriicon{
    width: 50px;
    height: 100px;
  }
  .kary_sobre_vriicon img{
    display: block;
    width: 26px;
    height: 26px;
  }
  .kary_sobre_vritext{
    width: 300px;
    height: 100px;
  }
  .kary_sobre_vritname{
    width: 100%;
    height: 34px;
    font-weight: 400;
    font-size: 20px;
    color: #999999;
    line-height: 24px;
    text-align: left;
    font-style: normal;
  }
  .kary_sobre_vritnumber{
    width: 100%;
    ont-weight: 400;
    font-size: 22px;
    color: #021627;
    line-height: 26px;
    text-align: left;
    font-style: normal;
  }
  #karycard_view .karycard_itemone{
    background: url(../assets/imgs/karycard_itemone.png) no-repeat top center;
    background-size: 540px 620px;
    background-size: cover;
  }
  #karycard_view .karycard_itemtwo{
    background: url(../assets/imgs/karycard_itemtwo.png) no-repeat top center;
    background-size: 540px 620px;
    background-size: cover;
  }
  #karycard_view .karycard_itemthr{
    background: url(../assets/imgs/karycard_itemthr.png) no-repeat top center;
    background-size: 540px 620px;
    background-size: cover;
  }
  #karycard_view .karycard_itemfur{
    background: url(../assets/imgs/karycard_itemfur.png) no-repeat top center;
    background-size: 540px 620px;
    background-size: cover;
  }
  #karycard_view .karycard_itemone:hover{
    background: url(../assets/imgs/karycard_itemone_active.png) no-repeat top center;
    background-size: 564px 644px;
    background-size: cover;
  }
  #karycard_view .karycard_itemtwo:hover{
    background: url(../assets/imgs/karycard_itemtwo_active.png) no-repeat top center;
    background-size: 564px 644px;
    background-size: cover;
  }
  #karycard_view .karycard_itemthr:hover{
    background: url(../assets/imgs/karycard_itemthr_active.png) no-repeat top center;
    background-size: 564px 644px;
    background-size: cover;
  }
  #karycard_view .karycard_itemfur:hover{
    background: url(../assets/imgs/karycard_itemfur_active.png) no-repeat top center;
    background-size: 564px 644px;
    background-size: cover;
  }
</style>
