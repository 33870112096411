<template>
  <div class="Index">
    <div class="libe_home_nav" v-if="scroll >= 88">
      <headNav :activeNav="'Inicio'" @handeleNavchange="onNavchange"></headNav>
    </div>
    <div v-if="scroll < 88">
      <homeNav :activeNav="'Inicio'" @handeleNavchange="onNavchange"></homeNav>
    </div>

    <Inicio></Inicio>

    <div class="karyfooter_wrap">
      <img src="@/assets/imgs/karyfooter_bgimg.webp" alt="">
      <div class="karyfooter_view">
        <div class="karyfooter_vtext">
          El Buró de Entidades Financieras contiene información de KARYWORLD, S.A. DE C.V., SOFOM, E.N.R. sobre las características de nuestra aplicación y nuestro desempeño frente a los usuarios en la prestación de servicios.
          La información que de ellas conste en el Buró y la indicación de que la información corresponde únicamente a la Entidad Financiera de que se trate y que para conocer la información de todo el sector a la que corresponda. Te invitamos a consultarlo en la página https://www.buro.gob.mx/
          <div class="karyfooter_vprivace" @click.stop="onGotoPrivace">

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headNav from "@/components/headNav.vue";
import homeNav from "@/components/homeNav.vue";
import Inicio from "@/components/Inicio.vue";

export default {
  name: 'Index',
  components: { headNav, homeNav, Inicio },
  data () {
    return {
      activeNav: "Inicio",
      scroll: 0,
    }
  },
  created() {
    let nav = localStorage.getItem('activeNav');
    if (nav) {
      this.activeNav = nav;
    } else {
      localStorage.setItem('activeNav', this.activeNav);
    }
  },
  mounted(){
    window.addEventListener('scroll',this.handleScroll)
  },
  methods: {
    handleScroll(){
      //或者使用document.querySelector('.class或者#id').scrollTop
      this.scroll = document.documentElement.scrollTop||document.body.scrollTop
    },
    onNavchange(type){
      console.log(type)
      if(type == 'Inicio'){
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // 可选，平滑滚动效果
        });
      } else if(type == 'Ventajas'){
        window.scrollTo({
          top: 1100,
          behavior: 'smooth' // 可选，平滑滚动效果
        });
      } else if(type == 'Sobre'){
        window.scrollTo({
          top: 2600,
          behavior: 'smooth' // 可选，平滑滚动效果
        });
      }
    },
    onGotoPrivace() {
      this.$router.push('/privace');
    }
  }
}
</script>

<style scoped>
  .karyfooter_wrap{
    width: 100%;
    height: 560px;
    position: relative;
  }
  .karyfooter_wrap img{
    display: block;
    width: 100%;
    height: 560px;
  }
  .karyfooter_view{
    width: 100%;
    height: 560px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .karyfooter_vtext{
    width: 1400px;
    height: auto;
    margin: 240px auto 0 auto;
    font-weight: 400;
    font-size: 28px;
    color: rgba(255,255,255,0.64);
    line-height: 38px;
    text-align: left;
    font-style: normal;
    position: relative;
  }
  .karyfooter_vprivace{
    width: 200px;
    height: 50px;
    position: absolute;
    left: 350px;
    top: -150px;
    cursor: pointer;
  }
  .libe_home_nav{
    width: 100%;
    height: auto;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1010;
  }
</style>
